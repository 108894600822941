import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import { createComponent } from '@/lib/vue';
import { IRequireField } from '@/typings';

interface IJsxProps {
  // model
  value?: string[];
  convenioId?: string | null;
  loteId?: string | null;
  initialValue?: string[] | null;
}

type IProps = IRequireField<IJsxProps, 'value'>;

export const TissLoteGuias = createComponent<IJsxProps>({
  name: 'TissLoteGuias',
  props: {
    value: { type: Array, required: true },
    convenioId: { type: String },
    loteId: { type: String },
    initialValue: { type: Array },
  },
  setup(props: IProps, ctx) {
    // const { $selected, setSelected, headers } = useData();

    // const { $data: $items, $loading } = useTissGuiasConsultasQuery(props);

    // const { handleSelectedChange } = useEvents({
    //   props,
    //   ctx,
    //   $items,
    //   setSelected,
    // });

    return () => (
      <PageSection divider hide={!props.convenioId}>
        <FormHeader
          title="Guias"
          text="Selecione as guias de consulta que farão parte deste lote:"
        />

        {/* <DataTable
          showSelect
          headers={headers}
          items={$items.value}
          loading={$loading.value}
          selectedItems={$selected.value}
          onSelect={handleSelectedChange}
        /> */}
      </PageSection>
    );
  },
});

// function useData() {
//   const [$selected, setSelected] = useValue<ITissLoteGuiaItem[]>([]);

//   const headers: Array<IDataTableHeader<ITissLoteGuiaItem>> = [
//     {
//       text: 'Paciente',
//       value: 'paciente',
//       mapValue: v => v.paciente,
//     },
//     {
//       text: 'Data da consulta',
//       value: 'atendimentoData',
//       mapValue: v => DateHelpers.formatDate(v.atendimentoData),
//     },
//   ];

//   return { $selected, setSelected, headers };
// }

// function useEvents({
//   props,
//   ctx,
//   $items,
//   setSelected,
// }: {
//   props: IProps;
//   ctx: SetupContext;
//   $items: Ref<ITissLoteGuiaItem[] | null>;
//   setSelected: (v: ITissLoteGuiaItem[]) => void;
// }) {
//   function handleSelectedChange(newValue: ITissLoteGuiaItem[] | null) {
//     setSelected(newValue || []);

//     const value = (newValue || []).map(v => v.id);

//     emitInput(value);
//   }

//   function emitInput(value: string[]) {
//     ctx.emit('input', value);
//   }

//   watchRun($items, items => {
//     if (props.initialValue) {
//       setSelected(
//         (items || []).filter(f => props.initialValue!.includes(f.id)),
//       );
//     }
//   });

//   return {
//     handleSelectedChange,
//     emitInput,
//   };
// }

// function useTissGuiasConsultasQuery(props: IProps) {
//   return useQueryConfig<
//     ITissGuiasConsultasQuery,
//     ITissLoteGuiaItem[],
//     ITissGuiasConsultasQueryVariables
//   >({
//     query: QueryGraphql.TissGuiasConsultasQuery,
//     variables() {
//       if (props.loteId) {
//         return {
//           where: {
//             OR: [
//               {
//                 convenioId: props.convenioId,
//                 loteId: props.loteId,
//               },
//               {
//                 convenioId: props.convenioId,
//                 loteId: null,
//               },
//             ],
//           },
//         };
//       }

//       return {
//         where: {
//           convenioId: props.convenioId,
//           loteId: null,
//         },
//       };
//     },
//     mapData: result =>
//       result?.tissGuiasConsultas.nodes.map(v => ({
//         id: v.id,
//         paciente: v.paciente.nome,
//         atendimentoData: v.atendimentoData,
//       })) || [],
//     enabled: () => !!props.convenioId,
//   });
// }
