import { InputSelect } from '@/components/form/inputs/InputSelect';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { createComponent } from '@/lib/vue';
import { computed } from '@vue/composition-api';

interface IProps {
  select: boolean;
  text: string;
  form: boolean;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const AlertSelectProfissional = createComponent<IProps, IEvents>({
  name: 'AlertSelectProfissional',
  props: {
    select: { type: Boolean, default: false },
    text: { type: String, required: true },
    form: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const { profissionalId, $items, $name } = useComputeds();

    function emitInput(value: string | null) {
      ctx.emit('input', value);
    }

    return () => (
      <v-card
        outlined
        class={[
          'mb-4 mx-auto w-full',
          props.select ? 'p-6' : 'p-4',
          props.form && 'max-w-screen-xl',
        ]}
      >
        <div class="flex items-center text-lg">
          <span class="font-normal text-gray-700">{props.text}</span>

          {props.select ? (
            <div style={{ maxWidth: '400px' }}>
              <InputSelect
                value={profissionalId}
                input={{
                  label: 'Profissional',
                  type: 'select',
                  items: $items.value,
                  hideDetails: true,
                }}
                onInput={emitInput}
                class="ml-4"
              />
            </div>
          ) : (
            <span class="ml-1 font-medium text-primary">{$name.value}</span>
          )}
        </div>
      </v-card>
    );
  },
});

function useComputeds() {
  const { profissionalId } = useRouteParams();

  const { $user, $clinica } = useAuthInfo();

  const $name = computed(
    () =>
      $clinica.value?.profissionais.find(f => f.id === profissionalId)?.nome ||
      $user.value?.nome,
  );

  const $items = computed(
    () =>
      $clinica.value?.profissionais.map(v => ({
        label: v.nome,
        value: v.id,
      })) || [],
  );

  return { profissionalId, $name, $items };
}
