import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { TissGuiaOdontologicaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  ITissGuiaOdontologicaFormSchema,
  ITissGuiaOdontologicaModel,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<ITissGuiaOdontologicaModel> {}

// TODO: implement form
export const TissGuiaOdontologicaForm = createComponent<IProps, IEvents>({
  name: 'TissGuiaOdontologicaForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const indexRoute = Routes.app.tiss(profissionalId).guiasOdontologicas.index;

    // TODO: handle agendimentoId, add to model on save
    // const agendamentoId = useRoute().query.aid as string | null;

    const { $form, $schema, emitDelete, emitSubmit } = useForm(props, ctx);

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection title="Geral">
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.geral}
            v-model={$form.value.model.geral}
          />
        </PageSection>

        <PageSection title="Dados do paciente" divider>
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.paciente}
            v-model={$form.value.model.paciente}
          />
        </PageSection>

        <PageSection title="Dados do contratado" divider>
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.contratado}
            v-model={$form.value.model.contratado}
          />
        </PageSection>

        <PageSection title="Dados do atendimento" divider>
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.atendimento}
            v-model={$form.value.model.atendimento}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  const { $form, emitSubmit, emitDelete, $schema } = useFormConfig<
    ITissGuiaOdontologicaModel,
    ITissGuiaOdontologicaFormSchema
  >({
    page: props.page,
    ctx,
    initialValue: {
      geral: {},
      paciente: {},
      contratado: {},
      atendimento: {},
    },
    mapSchema: model => {
      // TODO: better form schema
      return {
        geral: {},
        paciente: {},
        contratado: {},
        atendimento: {},
      };
    },
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await TissGuiaOdontologicaService.getById(id);

      if (!editValue) return redirectError(404);

      setFormModel({
        geral: {},
        paciente: {},
        contratado: {},
        atendimento: {},
      });
    },
  });

  return { $form, emitSubmit, emitDelete, $schema };
}
