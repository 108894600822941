import { MyPage } from '@/components/page/MyPage';
import { createComponent } from '@/lib/vue';
import { PropType } from 'vue';
import {
  ITissSelectProfissionalMode,
  ITissSelectProfissionalType,
  TissSelectProfissional,
} from './TissSelectProfissional';

export const TissPage = createComponent({
  name: 'TissPage',
  props: {
    title: { type: String, required: true },
    select: { type: Boolean, default: false },
    form: { type: Boolean, default: false },
    mode: {
      type: String as PropType<ITissSelectProfissionalMode>,
      required: true,
    },
    type: {
      type: String as PropType<ITissSelectProfissionalType>,
      required: true,
    },
  },
  setup(props, ctx) {
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <div class="flex flex-col">
          <TissSelectProfissional
            select={props.select}
            mode={props.mode}
            type={props.type}
            form={props.form}
          />

          <MyPage title={props.title} form={props.form}>
            {defaultSlot}
          </MyPage>
        </div>
      );
    };
  },
});
