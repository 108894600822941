import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { MyPage } from '@/components/page/MyPage';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import {
  getTissProcedimentoSexo,
  getTissProcedimentoTabela,
} from '@/lib/helpers/models/tissProcedimento';
import { TissProcedimentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IIndexDataTableActions,
  IIndexDataTableButton,
  ITissProcedimentoItem,
  ITissProcedimentosQuery,
  ITissProcedimentosQueryVariables,
  IDataTableHeader,
} from '@/typings';

export default createComponent({
  name: 'TissProcedimentosPage',
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const btn: IIndexDataTableButton = {
      text: 'Novo Procedimento',
      to: Routes.app.tiss(profissionalId).procedimentos.new,
    };

    const actions: IIndexDataTableActions = {
      editRoute: Routes.app.tiss(profissionalId).procedimentos.edit,
      removeMany: TissProcedimentoService.deleteMany,
    };

    const headers: Array<IDataTableHeader<ITissProcedimentoItem>> = [
      {
        text: 'Tabela',
        value: 'tabela',
        mapValue: v => getTissProcedimentoTabela(v.tabela),
        width: 240,
      },
      {
        text: 'Código',
        value: 'codigo',
        mapValue: v => v.codigo,
      },
      {
        text: 'Nome',
        value: 'nome',
        mapValue: v => v.nome,
      },
      {
        text: 'Sexo',
        value: 'sexo',
        mapValue: v => getTissProcedimentoSexo(v.sexo),
      },
    ];

    const { $data, $loading } = useTissProcedimentosQuery();

    return () => (
      <MyPage title="Procedimentos próprios">
        <IndexDataTable
          titleText="Procedimentos que são apenas das tabelas próprias: 00, 90, 98.  Usados para o faturamento TISS"
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </MyPage>
    );
  },
});

function useTissProcedimentosQuery() {
  return useQueryConfig<
    ITissProcedimentosQuery,
    ITissProcedimentoItem[],
    ITissProcedimentosQueryVariables
  >({
    query: QueryGraphql.TissProcedimentosQuery,
    variables: () => ({}),
    mapData: result =>
      result?.tissProcedimentos.nodes.map(v => ({
        id: v.id,
        tabela: v.tabela,
        codigo: v.codigo,
        nome: v.nome,
        sexo: v.sexo,
      })) || [],
  });
}
