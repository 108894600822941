import TissNextGuiaQuery from '@/graphql/tiss/tissNext/tissNextGuia.graphql';
import TissNextLoteQuery from '@/graphql/tiss/tissNext/tissNextLote.graphql';
import { apolloClient } from '@/plugins/apollo';
import { ITissNextGuiaQuery, ITissNextLoteQuery } from '@/typings';

export const TissNextGraphql = {
  async nextGuia(profissionalId: string) {
    return apolloClient
      .query<ITissNextGuiaQuery>({
        query: TissNextGuiaQuery,
        variables: { profissionalId },
      })
      .then(({ data }) => data?.tissNextGuia);
  },

  async nextLote(profissionalId: string) {
    return apolloClient
      .query<ITissNextLoteQuery>({
        query: TissNextLoteQuery,
        variables: { profissionalId },
      })
      .then(({ data }) => data?.tissNextLote);
  },
};
