import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenu } from '@/components/sidebars/context/ContextSidebarMenu';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IMenu } from '@/typings';

export default createComponent({
  name: 'TissSidebar',
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const menus: IMenu[] = [
      {
        text: 'TISS',
        header: true,
      },
      {
        text: 'Lotes',
        icon: MyIcons.tissLote,
        to: Routes.app.tiss(profissionalId).lotes.index,
      },
      {
        text: 'Guias',
        subheader: true,
      },
      {
        text: 'Consultas',
        icon: MyIcons.tissGuiaConsulta,
        to: Routes.app.tiss(profissionalId).guiasConsultas.index,
      },
      {
        text: 'SP/SADT',
        icon: MyIcons.tissGuiaSpSadt,
        to: Routes.app.tiss(profissionalId).guiasSpSadt.index,
      },
      {
        text: 'Honorários',
        icon: MyIcons.tissGuiaHonorarios,
        to: Routes.app.tiss(profissionalId).guiasHonorarios.index,
      },
      {
        text: 'Odontológicas (GTO)',
        icon: MyIcons.tissGuiaOdontologica,
        to: Routes.app.tiss(profissionalId).guiasOdontologicas.index,
      },
      {
        text: 'Cadastro',
        subheader: true,
      },
      {
        text: 'Procedimentos',
        icon: MyIcons.tissProcedimento,
        to: Routes.app.tiss(profissionalId).procedimentos.index,
      },
    ];

    return () => (
      <ContextSidebar>
        <ContextSidebarMenu menus={menus} />
      </ContextSidebar>
    );
  },
});
