import { TdInputNumber } from '@/components/datatable/columns/TdInputNumber';
import { DataTable } from '@/components/datatable/DataTable';
import FormHeader from '@/components/typography/FormHeader';
import { createComponent } from '@/lib/vue';
import {
  IDataTableHeader,
  ITissGuiaProcedimentoModel,
  TissGuiaTipo,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';
import { PropType } from 'vue';

interface IProps {
  value: ITissGuiaProcedimentoModel[];
  tipo: TissGuiaTipo;
}

interface IEvents {
  onInput(items: ITissGuiaProcedimentoModel[]): void;
}

export const TissProcedimentosTable = createComponent<IProps, IEvents>({
  name: 'TissProcedimentosTable',
  props: {
    value: {
      type: Array as PropType<ITissGuiaProcedimentoModel[]>,
      required: true,
    },
    tipo: { type: String as PropType<TissGuiaTipo>, required: true },
  },
  setup(props, ctx) {
    const headers = useHeaders({ props, ctx });

    return () => (
      <div id="TissProcedimentosTable" class="flex flex-col">
        <v-divider />

        <div class="flex flex-col p-4">
          <FormHeader title="Procedimentos" />

          <DataTable headers={headers} items={props.value} />
        </div>
      </div>
    );
  },
});

function useHeaders({
  props,
  ctx,
}: {
  props: IProps;
  ctx: SetupContext;
}): IDataTableHeader<ITissGuiaProcedimentoModel>[] {
  const { handleValorUnitarioInput } = useEvents({ props, ctx });

  return [
    {
      text: 'Tabela',
      value: 'tabela',
      mapValue: v => v.tabela?.slice(3, 5),
      width: 50,
      align: 'center',
      valueAlign: 'center',
      sortable: false,
    },
    {
      text: 'Código',
      value: 'codigo',
      mapValue: v => v.codigo,
      width: 80,
      align: 'center',
      valueAlign: 'center',
      sortable: false,
    },
    {
      text: 'Descrição',
      value: 'descricao',
      mapValue: v => v.descricao,
    },
    {
      text: 'Quantidade',
      value: 'quantidade',
      mapValue: v => v.quantidade,
      width: 80,
      align: 'center',
      valueAlign: 'center',
      sortable: false,
    },
    {
      text: 'Valor unitário',
      value: 'valorUnitario',
      mapValue: v => v.valorUnitario,
      width: 150,
      money: true,
      align: 'center',
      valueAlign: 'center',
      sortable: false,
      slot:
        props.tipo !== TissGuiaTipo.CONSULTA
          ? undefined
          : ({ header, item }) => (
              <TdInputNumber
                class="dense-input center-input"
                header={header}
                input={{
                  label: '',
                  type: 'money',
                  hideDetails: true,
                }}
                value={item.valorUnitario}
                onInput={value => handleValorUnitarioInput({ item, value })}
              />
            ),
    },
    {
      text: 'Total',
      value: 'valorTotal',
      mapValue: v => v.valorTotal,
      width: 160,
      money: true,
      align: 'center',
      valueAlign: 'center',
      sortable: false,
    },
  ];
}

function useEvents({ props, ctx }: { props: IProps; ctx: SetupContext }) {
  function handleValorUnitarioInput({
    item,
    value,
  }: {
    item: ITissGuiaProcedimentoModel;
    value: number | null;
  }) {
    const valorUnitario = value || 0;
    emitInput([
      ...props.value.filter(f => f.id !== item.id),
      {
        ...item,
        valorUnitario,
        valorTotal: valorUnitario * (item.quantidade || 0),
      },
    ]);
  }

  function emitInput(items: ITissGuiaProcedimentoModel[]) {
    ctx.emit('input', items);
  }

  return { handleValorUnitarioInput, emitInput };
}
