import { createComponent } from '@/lib/vue';
import { TissPage } from '../../components/TissPage';

// TODO: implement index page
export default createComponent({
  name: 'TissGuiasSpSadtsPage',
  setup(props, ctx) {
    return () => (
      <TissPage title="Guias SP/SADT" mode="view" type="guia" select></TissPage>
    );
  },
});
