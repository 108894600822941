import { useFormPage } from '@/lib/composables/form/useFormPage';
import { TissGuiaOdontologicaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { ITissGuiaOdontologicaModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { TissGuiaOdontologicaForm } from '../../components/forms/TissGuiaOdontologicaForm';
import { TissPage } from '../../components/TissPage';

// TODO: implement form page
export default createComponent({
  name: 'TissGuiaOdontologicaFormPage',
  setup(props, ctx) {
    const { page, handleSubmit, isEdit } =
      useFormPage<ITissGuiaOdontologicaModel>({
        ctx,
        async submitCallback(model) {
          if (page.id) {
            // return TissGuiaOdontologicaService.update(page.id, model);
          } else {
            // return TissGuiaOdontologicaService.create(model);
          }
        },
      });

    const $title = computed(() =>
      isEdit
        ? 'Editar Guia Odontológica (GTO)'
        : 'Nova Guia Odontológica (GTO)',
    );

    async function handleDelete(id: string) {
      return TissGuiaOdontologicaService.delete(id);
    }

    return () => (
      <TissPage
        title={$title.value}
        form
        mode={isEdit ? 'edit' : 'insert'}
        type="guia"
      >
        <TissGuiaOdontologicaForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </TissPage>
    );
  },
});
