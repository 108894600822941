import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { TissNextService } from '@/lib/services/tiss/tissNext.service';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IDataTableHeader,
  IIndexDataTableActions,
  IIndexDataTableButton,
  ITissLotesItem,
  ITissLotesQuery,
  ITissLotesQueryVariables,
} from '@/typings';
import { TissPage } from '../../components/TissPage';

// TODO: download xml
export default createComponent({
  name: 'TissLotesPage',
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    TissNextService.nextLote(profissionalId);

    const btn: IIndexDataTableButton = {
      text: 'Novo Lote',
      to: Routes.app.tiss(profissionalId).lotes.new,
    };

    const actions: IIndexDataTableActions = {
      editRoute: Routes.app.tiss(profissionalId).lotes.edit,
    };

    const headers: Array<IDataTableHeader<ITissLotesItem>> = [
      {
        text: 'Número',
        value: 'numero',
        mapValue: v => v.numero,
      },
      {
        text: 'Convênio',
        value: 'convenio',
        mapValue: v => v.convenio,
      },
      {
        text: 'Data',
        value: 'data',
        mapValue: v => DateHelpers.formatDate(v.data),
      },
      {
        text: 'Versão TISS',
        value: 'versaoTiss',
        mapValue: v => v.versaoTiss,
      },
      {
        text: 'Download',
        value: 'download',
        mapValue: v => v.download,
      },
    ];

    const { $data, $loading } = useTissLotesQuery(profissionalId);

    return () => (
      <TissPage title="Lotes" mode="view" type="lote" select>
        <IndexDataTable
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </TissPage>
    );
  },
});

function useTissLotesQuery(profissionalId: string) {
  return useQueryConfig<
    ITissLotesQuery,
    ITissLotesItem[],
    ITissLotesQueryVariables
  >({
    query: QueryGraphql.TissLotesQuery,
    variables: () => ({ profissionalId }),
    mapData: result =>
      result?.tissLotes.nodes.map(v => ({
        id: v.id,
        numero: v.numeroLote,
        convenio: v.convenio.nome,
        data: v.createdAt,
        versaoTiss: null,
        download: null,
      })) || [],
  });
}
