import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { ConstantsHelper } from '@/lib/constants/helper';
import { TissProcedimentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import { IFormSchema, ITissProcedimentoModel } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<ITissProcedimentoModel> {}

export const TissProcedimentoForm = createComponent<IProps, IEvents>({
  name: 'TissProcedimentoForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const indexRoute = Routes.app.tiss(profissionalId).procedimentos.index;

    const { $form, $schema, emitSubmit, emitDelete } = useForm(props, ctx);

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<
    ITissProcedimentoModel,
    IFormSchema<ITissProcedimentoModel>
  >({
    page: props.page,
    ctx,
    initialValue: {
      codigo: null,
      nome: null,
      tabela: null,
      sexo: null,
    },
    mapSchema: () => ({
      tabela: {
        label: 'Tabela',
        type: 'select',
        items: ConstantsHelper.tiss.procedimentoTabelas,
        validations: { required: true },
      },
      codigo: {
        label: 'Código',
        type: 'text',
        mask: 'tissProcedimentoCodigo',
        validations: {
          required: true,
          minLength: 8,
        },
      },
      nome: {
        label: 'Nome',
        type: 'text',
        validations: { required: true },
      },
      sexo: {
        label: 'Sexo',
        type: 'select',
        items: ConstantsHelper.tiss.procedimentoSexos,
        validations: { required: true },
      },
    }),
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await TissProcedimentoService.getById(id);

      if (!editValue) return redirectError(404);

      setFormModel({
        codigo: editValue.codigo,
        nome: editValue.nome,
        tabela: editValue.tabela,
        sexo: editValue.sexo,
      });
    },
  });
}
