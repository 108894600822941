import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { TissNextService } from '@/lib/services/tiss/tissNext.service';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IDataTableHeader,
  IIndexDataTableActions,
  IIndexDataTableButton,
  ITissGuiaConsultaFragment,
  ITissGuiasConsultasQuery,
  ITissGuiasConsultasQueryVariables,
} from '@/typings';
import { TissPage } from '../../components/TissPage';

// TODO: criar link para lote
// TODO: não permitir alterar guia relacionada com lote
export default createComponent({
  name: 'TissGuiasConsultasPage',
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    TissNextService.nextGuia(profissionalId);

    const btn: IIndexDataTableButton = {
      text: 'Nova Guia de Consulta',
      to: Routes.app.tiss(profissionalId).guiasConsultas.new(),
    };

    const actions: IIndexDataTableActions = {
      editRoute: Routes.app.tiss(profissionalId).guiasConsultas.edit,
    };

    const headers: Array<IDataTableHeader<ITissGuiaConsultaFragment>> = [
      {
        text: 'Paciente',
        value: 'paciente',
        mapValue: v => v.paciente,
      },
      {
        text: 'Convênio',
        value: 'convenio',
        mapValue: v => v.convenio,
      },
      {
        text: 'Data',
        value: 'atendimentoData',
        mapValue: v => DateHelpers.formatDate(v.atendimentoData),
      },
      {
        text: 'Lote',
        value: 'lote',
        mapValue: v => v.lote?.numeroLote,
      },
      {
        text: 'Número da guia',
        value: 'numeroGuiaPrestador',
        mapValue: v => v.numeroGuiaPrestador,
      },
    ];

    const { $data, $loading } = useTissGuiasConsultasQuery(profissionalId);

    return () => (
      <TissPage title="Guias de consulta" mode="view" type="guia" select>
        <IndexDataTable
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </TissPage>
    );
  },
});

function useTissGuiasConsultasQuery(profissionalId: string) {
  return useQueryConfig<
    ITissGuiasConsultasQuery,
    ITissGuiaConsultaFragment[],
    ITissGuiasConsultasQueryVariables
  >({
    query: QueryGraphql.TissGuiasConsultasQuery,
    variables: () => ({ profissionalId }),
    mapData: result => result?.tissGuiasConsultas.nodes || [],
  });
}
