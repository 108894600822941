import { useFormPage } from '@/lib/composables/form/useFormPage';
import { TissLoteService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { ITissLoteFormModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { TissLoteForm } from '../../components/lote/TissLoteForm';
import { TissPage } from '../../components/TissPage';

export default createComponent({
  name: 'TissLoteFormPage',
  setup(props, ctx) {
    const { page, handleSubmit, isEdit } = useFormPage<ITissLoteFormModel>({
      ctx,
      async submitCallback(model) {
        if (page.id) {
          // return TissLoteService.update(page.id, model);
        } else {
          // return TissLoteService.create(model);
        }
      },
    });

    const $title = computed(() =>
      isEdit ? 'Editar Lote de Guias' : 'Novo Lote de Guias',
    );

    async function handleDelete(id: string) {
      return TissLoteService.delete(id);
    }

    return () => (
      <TissPage
        title={$title.value}
        form
        mode={isEdit ? 'edit' : 'insert'}
        type="lote"
      >
        <TissLoteForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </TissPage>
    );
  },
});
