import { AlertSelectProfissional } from '@/components/alerts/AlertSelectProfissional';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { useRoute, useRouteParams } from '@/lib/composables/utils/useRouter';
import { createComponent } from '@/lib/vue';
import { router } from '@/routes';
import { userIsAdminAccount, userIsAdminClinica } from '@/store/utils/auth';
import { computed } from '@vue/composition-api';
import { PropType } from 'vue';

export type ITissSelectProfissionalMode = 'insert' | 'edit' | 'view';
export type ITissSelectProfissionalType = 'guia' | 'lote' | 'procedimento';

interface IProps {
  select: boolean;
  form: boolean;
  mode: ITissSelectProfissionalMode;
  type: ITissSelectProfissionalType;
}

export const TissSelectProfissional = createComponent<IProps>({
  name: 'TissSelectProfissional',
  props: {
    select: { type: Boolean, default: false },
    form: { type: Boolean, default: false },
    mode: {
      type: String as PropType<ITissSelectProfissionalMode>,
      required: true,
    },
    type: {
      type: String as PropType<ITissSelectProfissionalType>,
      required: true,
    },
  },
  setup(props, ctx) {
    const { $text, $select } = useComputeds(props);

    function handleInput(value: string | null) {
      const { profissionalId } = useRouteParams();

      if (!value || value === profissionalId) return;

      const route = useRoute().fullPath;

      const newRoute = route.replace(profissionalId, value);

      router.push(newRoute);
    }

    return () => (
      <AlertSelectProfissional
        select={!!$select.value}
        form={props.form}
        text={$text.value}
        onInput={handleInput}
      />
    );
  },
});

function useComputeds(props: IProps) {
  const $text = computed(() => {
    if (props.mode === 'insert') {
      return `Você está inserindo ${props.type} para:`;
    } else if (props.mode === 'edit') {
      return `Você está alterando ${props.type} de:`;
    }
    // else if (props.modo === 'view')
    return `Você está vizualizando ${props.type}s de:`;
  });

  const { $clinica } = useAuthInfo();

  const $select = computed(
    () =>
      props.select &&
      (userIsAdminClinica() || userIsAdminAccount()) &&
      $clinica.value &&
      $clinica.value.profissionais.length > 1,
  );

  return { $text, $select };
}
