import { useFormPage } from '@/lib/composables/form/useFormPage';
import { TissGuiaHonorariosService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { ITissGuiaHonorariosModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { TissGuiaHonorariosForm } from '../../components/forms/TissGuiaHonorariosForm';
import { TissPage } from '../../components/TissPage';

// TODO: implement form page
export default createComponent({
  name: 'TissGuiaHonorariosFormPage',
  setup(props, ctx) {
    const { page, handleSubmit, isEdit } =
      useFormPage<ITissGuiaHonorariosModel>({
        ctx,
        async submitCallback(model) {
          if (page.id) {
            // return TissGuiaHonorariosService.update(page.id, model);
          } else {
            // return TissGuiaHonorariosService.create(model);
          }
        },
      });

    const $title = computed(() =>
      isEdit ? 'Editar Guia de Honorarios' : 'Nova Guia de Honorarios',
    );

    async function handleDelete(id: string) {
      return TissGuiaHonorariosService.delete(id);
    }

    return () => (
      <TissPage
        title={$title.value}
        form
        mode={isEdit ? 'edit' : 'insert'}
        type="guia"
      >
        <TissGuiaHonorariosForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </TissPage>
    );
  },
});
