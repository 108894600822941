import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { useRoute, useRouteParams } from '@/lib/composables/utils/useRouter';
import { ConstantsHelper } from '@/lib/constants/helper';
import { LookupsConfigs } from '@/lib/form/lookups';
import { uuid } from '@/lib/helpers/uuid';
import {
  AgendamentoService,
  ClinicaService,
  ConvenioService,
  PacienteService,
  ProcedimentoService,
  TissGuiaConsultaService,
  UserService,
} from '@/lib/services';
import { TissNextService } from '@/lib/services/tiss/tissNext.service';
import { createComponent, watchLazy } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  IConvenioFragment,
  IFormInputType,
  IPacienteFragment,
  ITissGuiaConsultaFormSchema,
  ITissGuiaConsultaModel,
  TissGuiaTipo,
  TissTabela,
  TissTipoDocumento,
  TissTipoPessoa,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';
import { TissProcedimentosTable } from './utils/TissProcedimentosTable';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<ITissGuiaConsultaModel> {}

export const TissGuiaConsultaForm = createComponent<IProps, IEvents>({
  name: 'TissGuiaConsultaForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const indexRoute = Routes.app.tiss(profissionalId).guiasConsultas.index;

    const { $form, $schema, emitDelete, emitSubmit, isEdit } = useForm({
      props,
      ctx,
      profissionalId,
    });

    loadNumeroGuiaPrestador({ $form, isEdit, profissionalId });

    loadProfissionalInfo({ $form, isEdit, profissionalId });

    loadAgendamentoInfo({ $form, isEdit, profissionalId });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection title="Geral">
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.geral}
            v-model={$form.value.model.geral}
          />
        </PageSection>

        <PageSection title="Paciente" divider>
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.paciente}
            v-model={$form.value.model.paciente}
          />
        </PageSection>

        <TissProcedimentosTable
          v-model={$form.value.model.procedimentos}
          tipo={TissGuiaTipo.CONSULTA}
        />

        <PageSection title="Contratado e Executante" divider>
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.contratado}
            v-model={$form.value.model.contratado}
          />
        </PageSection>

        <PageSection title="Dados do atendimento" divider>
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.atendimento}
            v-model={$form.value.model.atendimento}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useForm({
  props,
  ctx,
  profissionalId,
}: {
  props: IProps;
  ctx: SetupContext;
  profissionalId: string;
}) {
  return useFormConfig<ITissGuiaConsultaModel, ITissGuiaConsultaFormSchema>({
    page: props.page,
    ctx,
    initialValue: {
      geral: {
        agendamentoId: null,
        convenioId: null,
        registroANS: null,
        numeroGuiaPrestador: null,
        numeroGuiaOperadora: null,
      },
      paciente: {
        pacienteId: null,
        numeroCarteira: null,
        dataNascimento: null,
        nome: null,
        cns: null,
      },
      contratado: {
        tipoDocumento: TissTipoDocumento.CODIGO_NA_OPERADORA,
        tipoPessoa: TissTipoPessoa.JURIDICA,
        documento: null,
        contratadoNome: null,
        cnes: null,
        profissionalNome: null,
        conselho: null,
        numeroConselho: null,
        uf: null,
        cbos: null,
      },
      atendimento: {
        data: null,
        tipoConsulta: null,
        indicacaoAcidente: '9',
        observacao: null,
      },
      procedimentos: [
        {
          id: uuid(),
          tabela: TissTabela.TB_22,
          codigo: '10101012',
          descricao: 'CONSULTA',
          quantidade: 1,
          valorUnitario: 0,
          valorTotal: 0,
        },
      ],
    },
    mapSchema: (model, $form) => ({
      geral: {
        convenioId: {
          label: 'Convênio',
          type: 'autocomplete',
          itemLabel: 'nome',
          lookup: LookupsConfigs.convenio({
            noParticular: true,
            onModelChange(convenio) {
              setConvenioInfo({ $form, convenio, profissionalId });
            },
          }),
          validations: { required: true },
          layout: { sm: 6 },
        },
        registroANS: {
          label: 'Registro ANS',
          type: 'text',
          mask: 'numbers6',
          validations: { required: true },
          layout: { maxWidth: 200 },
        },
        numeroGuiaPrestador: {
          label: 'Número Guia Prestador',
          type: 'text',
          mask: 'numbers20',
          validations: { required: true },
          layout: { width: 260 },
        },
        numeroGuiaOperadora: {
          label: 'Número Guia Operadora',
          type: 'text',
          maxLength: 20,
          layout: { width: 260 },
        },
      },
      paciente: {
        pacienteId: {
          label: 'Paciente',
          type: 'autocomplete',
          itemLabel: 'nome',
          lookup: LookupsConfigs.paciente({
            onModelChange(paciente) {
              setPacienteInfo({ $form, paciente });
            },
          }),
          validations: { required: true },
          layout: { sm: 6 },
        },
        dataNascimento: {
          label: 'Data de nascimento',
          type: 'date',
          validations: { required: true },
          layout: { width: 280 },
        },
        numeroCarteira: {
          label: 'Número da carteira',
          type: 'text',
          maxLength: 20,
          validations: { required: true },
          layout: { width: 260 },
        },
        cns: {
          label: 'CNS',
          type: 'text',
          maxLength: 15,
          layout: { width: 260 },
        },
      },
      contratado: {
        profissionalNome: {
          label: 'Profissional Executante',
          type: 'text',
          maxLength: 70,
          validations: { required: true },
        },
        tipoDocumento: {
          label: 'Tipo de documento',
          type: 'select',
          items: ConstantsHelper.tiss.tipoDocumento,
          validations: { required: true },
          layout: { width: 250 },
        },
        documento: mapSchemaContratadoDocumento(model),
        conselho: {
          label: 'Conselho',
          type: 'select',
          items: ConstantsHelper.tiss.conselhos,
          validations: { required: true },
          layout: { width: 200 },
        },
        numeroConselho: {
          label: 'Número no conselho',
          type: 'text',
          maxLength: 15,
          validations: { required: true },
          layout: { width: 240 },
        },
        uf: {
          label: 'UF',
          type: 'select',
          items: ConstantsHelper.estados,
          itemLabel: 'sigla',
          itemValue: 'sigla',
          validations: { required: true },
          layout: { maxWidth: 100 },
        },
        cbos: {
          label: 'Especialidade (CBO-S)',
          type: 'autocomplete',
          items: ConstantsHelper.tiss.cbo,
          validations: { required: true },
        },
        tipoPessoa: {
          label: 'Tipo de pessoa',
          type: 'select',
          items: ConstantsHelper.tiss.tipoPessoa,
          validations: { required: true },
          layout: { width: 200 },
        },
        contratadoNome: {
          label: 'Nome do Contratado',
          type: 'text',
          disabled: model.contratado.tipoPessoa === TissTipoPessoa.FISICA,
          maxLength: 70,
          validations: { required: true },
          layout: { sm: 6 },
        },
        cnes: {
          label: 'CNES',
          type: 'text',
          maxLength: 7,
          validations: { required: true },
          layout: { width: 200 },
        },
      },
      atendimento: {
        data: {
          label: 'Data do atendimento',
          type: 'date',
          validations: { required: true },
          layout: { width: 270 },
        },
        tipoConsulta: {
          label: 'Tipo da consulta',
          type: 'select',
          items: ConstantsHelper.tiss.tipoConsulta,
          validations: { required: true },
          layout: { width: 280 },
        },
        indicacaoAcidente: {
          label: 'Indicação de acidente',
          type: 'select',
          items: ConstantsHelper.tiss.indicadorAcidente,
          validations: { required: true },
          layout: { width: 240 },
        },
        observacao: { label: 'Observação', type: 'textarea' },
      },
    }),
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await TissGuiaConsultaService.getById(id);

      if (!editValue) return redirectError(404);

      setFormModel({
        geral: {
          agendamentoId: editValue.agendamento?.id,
          convenioId: editValue.convenio.id,
          registroANS: editValue.registroANS,
          numeroGuiaPrestador: editValue.numeroGuiaPrestador,
          numeroGuiaOperadora: editValue.numeroGuiaOperadora,
        },
        paciente: {
          pacienteId: editValue.paciente.id,
          numeroCarteira: editValue.beneficiarioNumeroCarteira,
          dataNascimento: editValue.pacienteDataNascimento,
          nome: editValue.beneficiarioNome,
          cns: editValue.beneficiarioNumeroCNS,
        },
        contratado: {
          tipoDocumento: editValue.contratadoTipoDocumento,
          tipoPessoa: editValue.contratadoTipoPessoa,
          documento:
            editValue.contratadoTipoDocumento ===
            TissTipoDocumento.CODIGO_NA_OPERADORA
              ? editValue.contratadoCodigoPrestadorNaOperadora
              : editValue.contratadoTipoDocumento === TissTipoDocumento.CPF
              ? editValue.contratadoCpf
              : editValue.contratadoCnpj,
          contratadoNome: editValue.contratadoNome,
          cnes: editValue.contratadoCNES,
          profissionalNome: editValue.profissionalNome,
          conselho: editValue.profissionalConselho,
          numeroConselho: editValue.profissionalNumeroConselho,
          uf: editValue.profissionalUF,
          cbos: editValue.profissionalCBOS,
        },
        atendimento: {
          data: editValue.atendimentoData,
          tipoConsulta: editValue.atendimentoTipoConsulta,
          indicacaoAcidente: editValue.indicacaoAcidente,
          observacao: editValue.observacao,
        },
        procedimentos: [
          {
            id: uuid(),
            tabela: TissTabela.TB_22,
            codigo: '10101012',
            descricao: 'CONSULTA',
            quantidade: 1,
            valorUnitario: editValue.procedimentoValor,
            valorTotal: 0,
          },
        ],
      });
    },
  });

  function mapSchemaContratadoDocumento({
    contratado,
  }: ITissGuiaConsultaModel): IFormInputType {
    if (contratado.tipoDocumento === TissTipoDocumento.CODIGO_NA_OPERADORA) {
      return {
        label: 'Código na Operadora',
        type: 'text',
        maxLength: 14,
        validations: { required: true },
        layout: { width: 240 },
      };
    } else if (contratado.tipoDocumento === TissTipoDocumento.CPF) {
      return {
        label: 'CPF',
        type: 'text',
        mask: 'cpf',
        validations: { required: true, cpf: true },
        layout: { width: 240 },
      };
    }

    return {
      label: 'CNPJ',
      type: 'text',
      mask: 'cnpj',
      validations: { required: true, cnpj: true },
      layout: { width: 240 },
    };
  }
}

function setPacienteInfo({
  $form,
  paciente,
}: Pick<ReturnType<typeof useForm>, '$form'> & {
  paciente: IPacienteFragment | null;
}) {
  $form.value.model.paciente.pacienteId = paciente?.id || null;

  $form.value.model.paciente.nome = paciente?.nome;
  $form.value.model.paciente.dataNascimento = paciente?.dataNascimento;
  $form.value.model.paciente.cns = paciente?.cns;

  $form.value.model.paciente.numeroCarteira = paciente?.convenios?.find(
    f => f.convenio.id === $form.value.model.geral.convenioId,
  )?.numeroCarteirinha;
}

function setConvenioInfo({
  $form,
  convenio,
  profissionalId,
}: Pick<ReturnType<typeof useForm>, '$form'> & {
  convenio: IConvenioFragment | null;
  profissionalId: string;
}) {
  if (convenio?.particular) return;

  $form.value.model.geral.convenioId = convenio?.id || null;

  $form.value.model.geral.registroANS = convenio?.registroAns;

  if (
    $form.value.model.contratado.tipoDocumento ===
    TissTipoDocumento.CODIGO_NA_OPERADORA
  ) {
    $form.value.model.contratado.documento = convenio?.profissionais?.find(
      f => f.profissional.id === profissionalId,
    )?.codigoOperadora;
  }
}

async function loadNumeroGuiaPrestador({
  isEdit,
  profissionalId,
  $form,
}: Pick<ReturnType<typeof useForm>, '$form' | 'isEdit'> & {
  profissionalId: string;
}) {
  if (isEdit) return;

  const next = await TissNextService.getNext({ profissionalId, tipo: 'guia' });

  if (!next?.guia) return;

  $form.value.model.geral.numeroGuiaPrestador = next.guia.toString();
}

async function loadProfissionalInfo({
  isEdit,
  profissionalId,
  $form,
}: Pick<ReturnType<typeof useForm>, '$form' | 'isEdit'> & {
  profissionalId: string;
}) {
  if (isEdit) return;

  const { clinicaId } = useAuthInfo();

  if (!clinicaId) return;

  const [clinica, profissional] = await Promise.all([
    ClinicaService.getById(clinicaId),
    UserService.getById(profissionalId),
  ]);

  if (!clinica || !profissional) return;

  $form.value.model.contratado.contratadoNome = clinica.nome;
  $form.value.model.contratado.cnes = clinica.cnes;

  $form.value.model.contratado.profissionalNome = profissional.nome;
  $form.value.model.contratado.conselho = profissional.conselho;
  $form.value.model.contratado.numeroConselho = profissional.conselhoRegistro;
  $form.value.model.contratado.uf = profissional.conselhoUf;
  $form.value.model.contratado.cbos = profissional.cbo;

  watchLazy(
    () => $form.value.model.contratado.tipoPessoa,
    tipoPessoa => {
      $form.value.model.contratado.contratadoNome =
        tipoPessoa === TissTipoPessoa.FISICA ? '' : clinica.nome;
    },
  );
}

async function loadAgendamentoInfo({
  isEdit,
  profissionalId,
  $form,
}: Pick<ReturnType<typeof useForm>, '$form' | 'isEdit'> & {
  profissionalId: string;
}) {
  const agendamentoId = useRoute().query.aid as string | null;

  if (isEdit || !agendamentoId) return;

  const agendamento = await AgendamentoService.getById(agendamentoId);

  if (
    !agendamento?.convenio?.id ||
    !agendamento?.paciente?.id ||
    agendamento.procedimentos?.length !== 1
  )
    return;

  const [convenio, paciente, procedimento] = await Promise.all([
    ConvenioService.getById(agendamento.convenio.id),
    PacienteService.getById(agendamento.paciente.id),
    ProcedimentoService.getById(agendamento.procedimentos[0].procedimento.id),
  ]);

  if (!convenio || !paciente || !procedimento) return;

  setConvenioInfo({ $form, convenio, profissionalId });

  setPacienteInfo({ $form, paciente });

  // Set valor da consulta
  $form.value.model.procedimentos[0].valorUnitario =
    procedimento.convenios?.find(f => f.convenio.id === convenio.id)?.valor ||
    procedimento.valor ||
    0;
  $form.value.model.procedimentos[0].valorTotal =
    $form.value.model.procedimentos[0].valorUnitario *
    $form.value.model.procedimentos[0].quantidade;

  $form.value.model.geral.agendamentoId = agendamentoId;
}
