import { useFormPage } from '@/lib/composables/form/useFormPage';
import { TissGuiaConsultaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { ITissGuiaConsultaModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { TissGuiaConsultaForm } from '../../components/forms/TissGuiaConsultaForm';
import { TissPage } from '../../components/TissPage';

// TODO: implement form page
export default createComponent({
  name: 'TissGuiaConsultaFormPage',
  setup(props, ctx) {
    const { page, handleSubmit, isEdit } = useFormPage<ITissGuiaConsultaModel>({
      ctx,

      async submitCallback(model) {
        if (page.id) {
          // return TissGuiaConsultaService.update(page.id, model);
        } else {
          // return TissGuiaConsultaService.create(model);
        }
      },
    });

    const $title = computed(() =>
      isEdit ? 'Editar Guia de Consulta' : 'Nova Guia de Consulta',
    );

    async function handleDelete(id: string) {
      return TissGuiaConsultaService.delete(id);
    }

    return () => (
      <TissPage
        title={$title.value}
        form
        mode={isEdit ? 'edit' : 'insert'}
        type="guia"
      >
        <TissGuiaConsultaForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </TissPage>
    );
  },
});
