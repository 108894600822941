import { MyPage } from '@/components/page/MyPage';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { TissProcedimentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { ITissProcedimentoModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { TissProcedimentoForm } from '../../components/forms/TissProcedimentoForm';

export default createComponent({
  name: 'TissProcedimentoFormPage',
  setup(props, ctx) {
    const { page, handleSubmit, isEdit } = useFormPage({
      ctx,
      async submitCallback(model: ITissProcedimentoModel) {
        if (page.id) {
          return TissProcedimentoService.update(page.id, model);
        } else {
          return TissProcedimentoService.create(model);
        }
      },
    });

    const $title = computed(() =>
      isEdit ? 'Editar Procedimento próprio' : 'Novo Procedimento próprio',
    );

    async function handleDelete(id: string) {
      return TissProcedimentoService.delete(id);
    }

    return () => (
      <MyPage title={$title.value} form>
        <TissProcedimentoForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </MyPage>
    );
  },
});
