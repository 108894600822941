import { TissNextGraphql } from '@/graphql/tiss/tissNext/TissNextGraphql';
import { handleError } from '@/lib/helpers/error';
import { store } from '@/store';
import { TissState } from '@/store/modules/tiss.store';
import { ITissNextState } from '@/typings';

export const TissNextService = {
  async nextGuia(profissionalId: string): Promise<ITissNextState | undefined> {
    try {
      const guia = await TissNextGraphql.nextGuia(profissionalId);

      TissState.setNext({ guia, profissionalId });

      return {
        guia,
        lote: null,
        profissionalId,
      };
    } catch (error) {
      handleError(error);
    }
  },

  async nextLote(profissionalId: string): Promise<ITissNextState | undefined> {
    try {
      const lote = await TissNextGraphql.nextLote(profissionalId);

      TissState.setNext({ lote, profissionalId });

      return {
        lote,
        guia: null,
        profissionalId,
      };
    } catch (error) {
      handleError(error);
    }
  },

  async getNext({
    profissionalId,
    tipo,
  }: {
    profissionalId: string;
    tipo: 'guia' | 'lote';
  }): Promise<ITissNextState | null> {
    let next = store.state.tiss.next.find(
      f => f.profissionalId === profissionalId,
    );

    if (!next) {
      next =
        tipo === 'guia'
          ? await TissNextService.nextGuia(profissionalId)
          : await TissNextService.nextLote(profissionalId);
    }

    return next || null;
  },
};
