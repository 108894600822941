import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import { useValue } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { ConstantsHelper } from '@/lib/constants/helper';
import { TissLoteVersao } from '@/lib/constants/helper/tiss/loteVersao';
import { LookupsConfigs } from '@/lib/form/lookups';
import { TissLoteService } from '@/lib/services';
import { TissNextService } from '@/lib/services/tiss/tissNext.service';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  IFormSchema,
  IFormValidationErrors,
  ITissLoteFormModel,
  ITissLoteModel,
  TissLoteTipo,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';
import { TissLoteGuias } from './TissLoteGuias';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<ITissLoteFormModel> {}

export const TissLoteForm = createComponent<IProps, IEvents>({
  name: 'TissLoteForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const indexRoute = Routes.app.tiss(profissionalId).lotes.index;

    const { $form, $schema, emitDelete, emitSubmit, isEdit, $guiasIds } =
      useForm(props, ctx);

    loadNumeroLote({ $form, isEdit, profissionalId });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection title="Dados do lote">
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model.lote}
          />
        </PageSection>

        <TissLoteGuias
          convenioId={$form.value.model.lote.convenioId}
          loteId={props.page.id}
          initialValue={$guiasIds.value}
          v-model={$form.value.model.guiasIds}
        />
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  const [$guiasIds, setGuiasIds] = useValue<string[]>([]);

  const formConfig = useFormConfig<
    ITissLoteFormModel,
    IFormSchema<ITissLoteModel>
  >({
    page: props.page,
    ctx,
    initialValue: {
      lote: {
        tipo: TissLoteTipo.GUIA_CONSULTA,
        convenioId: null,
        prestadorCodigoNaOperadora: null,
        registroANS: null,
        numeroLote: null,
        versao: TissLoteVersao.V3_05_00,
      },
      guiasIds: [],
    },
    mapSchema: (model, $form) => {
      return {
        tipo: {
          label: 'Tipo',
          type: 'select',
          items: ConstantsHelper.tiss.loteTipo,
          validations: { required: true },
          layout: { width: 260 },
        },
        versao: {
          label: 'Versão',
          type: 'select',
          items: ConstantsHelper.tiss.loteVersao,
          validations: { required: true },
          layout: { width: 200 },
        },
        convenioId: {
          label: 'Convênio',
          type: 'autocomplete',
          itemLabel: 'nome',
          lookup: LookupsConfigs.convenio({
            noParticular: true,
            onModelChange(value) {
              $form.value.model.lote.registroANS = value?.registroAns || null;
            },
          }),
          validations: { required: true },
          layout: { sm: 6 },
        },
        registroANS: {
          label: 'Registro ANS',
          type: 'text',
          mask: 'numbers6',
          validations: { required: true },
          layout: { sm: 6 },
        },
        numeroLote: {
          label: 'Número do lote',
          type: 'text',
          mask: 'numbers12',
          validations: { required: true },
          layout: { sm: 6 },
        },
        prestadorCodigoNaOperadora: {
          label: 'Código na operadora',
          type: 'text',
          maxLength: 14,
          validations: { required: true },
          layout: { sm: 6 },
        },
      };
    },
    mapValueToValidate: model => model.lote,
    mapCustomErrors: model => {
      const errors: IFormValidationErrors[] = [];

      if (model.guiasIds.length === 0) {
        errors.push({
          label: 'Guias',
          error: 'selecione ao menos uma guia',
        });
      }

      return errors;
    },
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await TissLoteService.getById(id);

      if (!editValue) return redirectError(404);

      setGuiasIds((editValue.guiasConsultas || []).map(v => v.id));

      setFormModel({
        lote: {
          tipo: editValue.tipo,
          convenioId: editValue.convenio.id,
          prestadorCodigoNaOperadora:
            editValue.prestadorCodigoPrestadorNaOperadora,
          registroANS: editValue.registroANS,
          numeroLote: editValue.numeroLote,
          versao: editValue.versao,
        },
        guiasIds: [...$guiasIds.value],
      });
    },
  });

  return { ...formConfig, $guiasIds, setGuiasIds };
}

async function loadNumeroLote({
  isEdit,
  profissionalId,
  $form,
}: Pick<ReturnType<typeof useForm>, '$form' | 'isEdit'> & {
  profissionalId: string;
}) {
  if (isEdit) return;

  const next = await TissNextService.getNext({ profissionalId, tipo: 'lote' });

  $form.value.model.lote.numeroLote = next?.lote?.toString() || null;
}
