import { useFormPage } from '@/lib/composables/form/useFormPage';
import { TissGuiaSpSadtService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { ITissGuiaSpSadtModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { TissGuiaSpSadtForm } from '../../components/forms/TissGuiaSpSadtForm';
import { TissPage } from '../../components/TissPage';

// TODO: implement form page
export default createComponent({
  name: 'TissGuiaSpSadtFormPage',
  setup(props, ctx) {
    const { page, handleSubmit, isEdit } = useFormPage<ITissGuiaSpSadtModel>({
      ctx,

      async submitCallback(model) {
        if (page.id) {
          // return TissGuiaSpSadtService.update(page.id, model);
        } else {
          // return TissGuiaSpSadtService.create(model);
        }
      },
    });

    const $title = computed(() =>
      isEdit ? 'Editar Guia SP/SADT' : 'Nova Guia SP/SADT',
    );

    async function handleDelete(id: string) {
      return TissGuiaSpSadtService.delete(id);
    }

    return () => (
      <TissPage
        title={$title.value}
        form
        mode={isEdit ? 'edit' : 'insert'}
        type="guia"
      >
        <TissGuiaSpSadtForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </TissPage>
    );
  },
});
