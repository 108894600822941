import { createComponent } from '@/lib/vue';
import { TissPage } from '../../components/TissPage';

// TODO: implement index page
export default createComponent({
  name: 'TissGuiasConsultasPage',
  setup(props, ctx) {
    return () => (
      <TissPage
        title="Guias odontológicas (GTO)"
        mode="view"
        type="guia"
        select
      ></TissPage>
    );
  },
});
